<template>
  <v-card outlined dense>
    <v-responsive
      v-show="settings.agent_video || status.screen"
      :aspect-ratio="16 / 9"
    >
      <div
        v-show="!status.screen"
        id="camera"
        ref="camera"
        class="fill-height"
      ></div>

      <div
        v-show="status.screen"
        id="screen"
        ref="screen"
        class="fill-height"
      ></div>
    </v-responsive>
    <v-card-actions v-if="showControls">
      <v-btn
        v-if="settings.agent_video"
        :disabled="!status.publishing"
        icon
        :title="`${status.video ? 'Disattiva' : 'Attiva'} video`"
        @click="toggleVideo"
      >
        <v-icon v-if="status.video" color="primary">mdi-video</v-icon>
        <v-icon v-else color="secondary">mdi-video-off</v-icon>
      </v-btn>

      <v-btn
        v-if="settings.agent_video && videoDevices.length > 1"
        icon
        :disabled="!status.publishing || !status.video"
        title="Inverti Fotocamera"
        @click="switchCamera"
      >
        <v-icon>mdi-camera-switch</v-icon>
      </v-btn>

      <v-btn
        :disabled="!status.publishing"
        icon
        :title="`${status.audio ? 'Disattiva' : 'Attiva'} audio`"
        @click="toggleAudio"
      >
        <v-icon v-if="status.audio" color="primary">mdi-microphone</v-icon>
        <v-icon v-else color="secondary">mdi-microphone-off</v-icon>
      </v-btn>

      <v-divider vertical class="mx-3" />
      <v-btn
        v-if="bodyPixReady"
        :disabled="!status.publishing || !status.video"
        icon
        :color="status.blur ? 'secondary' : 'primary'"
        :title="`${status.blur ? 'Disattiva' : 'Attiva'} sfocatura sfondo`"
        @click="status.blur = !status.blur"
      >
        <v-icon v-if="!status.blur">mdi-blur</v-icon>
        <v-icon v-else>mdi-blur-off</v-icon>
      </v-btn>

      <v-btn
        v-if="settings.agent_share_screen && canShareScreen && !status.screen"
        icon
        title="Avvia Convisione Schermo"
        :disabled="!status.publishing"
        @click="shareScreen"
      >
        <v-icon color="primary">mdi-television</v-icon>
      </v-btn>

      <v-btn
        v-if="status.screen"
        icon
        title="Interrompi Convisione Schermo"
        @click="() => unpublish('screen')"
      >
        <v-icon color="secondary">mdi-television-off</v-icon>
      </v-btn>
    </v-card-actions>

    <CameraEffectSource
      v-if="settings.agent_video && settings.agent_video_effects"
      v-show="false"
      ref="effects"
      :width="1280"
      :height="720"
      :enable-video="true"
      :blur="status.blur"
      :fake-background="status.fake_bg"
      :load-tensor-flow="settings.agent_video_effects"
      front-camera
      @bodyPixReady="bodyPixReady = true"
    />
  </v-card>
</template>

<script>
import OT from '@opentok/client'
import CameraEffectSource from '@components/sessions/inc/CameraEffectSource.vue'
import {
  isChrome,
  isChromium,
  isEdgeChromium,
  isFirefox,
  isMobile,
  isOpera,
  isSafari,
} from 'mobile-device-detect'
import {
  initialConfig,
  isOwn,
  joinSession,
  leaveSession,
} from '@/src/utils/session'
import { promiseFromCallback, when } from '@/src/utils/functions'
import { createPublisher } from '@/src/views/dev_utils'

export default {
  name: 'AgentPublisher',
  components: { CameraEffectSource },
  props: {
    session: {
      type: OT.Session,
      required: true,
      default() {
        return {}
      },
    },
    settings: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
    showControls: {
      type: Boolean,
      required: false,
      defaultValue: false,
    },
  },
  data() {
    return {
      publishers: {
        camera: null,
        screen: null,
      },
      publisher: null,
      publisher_screen: null,
      devices: [],
      bodyPixReady: false,
      canShareScreen: false,
      status: initialConfig.agent.getStatusBySettings(this.settings),
      // Opt generation are enclosed in an helper
      // as they are a pretty static behaviour and will clutter
      opts: {
        camera: initialConfig.agent.getOptionsBySettingsForCamera(
          this.settings
        ),
        screen: initialConfig.customer.getOptionsBySettingsForScreen(
          this.settings
        ),
      },
    }
  },
  computed: {
    videoDevices() {
      return this.devices.filter((d) => d.kind === 'videoInput')
    },
    audioDevices() {
      return this.devices.filter((d) => d.kind === 'audioInput')
    },
  },
  async mounted() {
    this.devices = await this.getDevices()
    this.canShareScreen = await this.checkScreenSharing()
    // This should never fire as this component is rendered when the connection is already established
    this.session.on('sessionConnected', this.onSessionConnected)
    this.session.on('sessionDisconnected', this.onSessionDisconnected)
    this.session.on(
      'signal:customerDisconnected',
      when(this.isNotOwnEvent, () => {
        this.$dialog.notify.warning('Il cliente ha terminato la sessione.', {
          position: 'bottom-center',
          timeout: 2000,
        })
      })
    )
    this.$emit('beforeCreate')
    this.publishers.camera = await this.createPublisher('camera')
    this.$emit('afterCreate')
    if (!this.publishers.camera) {
      return
    }
    // This fires when YOUR stream joins the session,
    // When OTHER people's streams join, it's the session object firing this event
    this.publishers.camera.on('streamCreated', this.published('camera'))
    this.publishers.camera.on('streamDestroyed', this.unpublished('camera'))

    try {
      await this.publish('camera')
      this.status.publishing = true
    } catch (error) {
      this.$emit('error', error)
      this.status.publishing = false
    }

    this.$emit('complete')
  },
  async beforeDestroy() {
    /* await this.unpublish('camera') */
  },
  methods: {
    async checkMedia() {
      try {
        await OT.getUserMedia()
      } catch (err) {
        this.$dialog.notify.error(
          'Impossibile procedere, permessi negati. Modifica le impostazioni del tuo browser.'
        )
        this.$emit('error', this.publisher)
      }
    },

    async getDevices() {
      try {
        const devices = await promiseFromCallback(OT.getDevices)
        return devices
      } catch (error) {
        console.error('Impossibile ottenere dispositivi', error)
        this.$dialog.notify.warning(
          'Impossibile accedere a microfono e webcam',
          this.notificationSettings
        )
      }
    },

    /**
     * Returns true if the event is generated by somebody else
     */
    isNotOwnEvent(event) {
      return !isOwn(this.session, event)
    },

    onSessionConnected() {
      this.status.connected = true
    },
    onSessionDisconnected() {
      this.status.connected = false
    },
    async publish(target) {
      await joinSession(this.session, this.publishers[target])
    },
    /** Unpublish the target, either a camera or screen sharing stream */
    async unpublish(target) {
      await leaveSession(this.session, this.publishers[target])
      if (target === 'camera') this.status.publishing = false
      if (target === 'screen') this.status.screen = false
    },

    /** Handles published events */
    published(template) {
      return ({ stream }) => {
        this.$emit('beforePublish')
        switch (template) {
          case 'camera':
            break
          case 'screen':
            this.$dialog.notify.success(
              'Condivisione schermo abilitata',
              this.notificationSettings
            )
            this.status.screen = true
            break
        }
        this.$emit('published', stream)
      }
    },
    unpublished(template) {
      return ({ stream }) => {
        this.$emit('beforeUnpublish')
        switch (template) {
          case 'camera':
            break
          case 'screen':
            this.$dialog.notify.info(
              'Condivisione schermo interrotta',
              this.notificationSettings
            )
            this.status.screen = false
            this.publishers.screen = null
            break
        }
        this.$emit('unpublished', stream)
      }
    },

    async createPublisher(template) {
      const finalOpts = this.opts[template]
      // Cannot inject ref from outside the component
      // So it has to do it here
      // exclude screen sharing from effects
      if (!finalOpts.videoSource) {
        if (this.settings.agent_video && this.settings.agent_video_effects) {
          finalOpts.videoSource = this.$refs.effects.getVideoTrack(15)
        }
      }

      try {
        const publisher = await createPublisher(this.$refs[template], finalOpts)
        return publisher
      } catch (error) {
        console.error(error)
        // If the user disallow camera mic permissions just set permissions as false
        if (error.name === 'OT_USER_MEDIA_ACCESS_DENIED') {
          this.$dialog.notify.error(
            'Impossibile accedere a camera e/o microfono.',
            this.notificationSettings
          )
          this.status.permissions = false
          // If it's an unknown error, propagate it
        }
      }
    },

    async toggleVideo() {
      try {
        this.status.video = !this.status.video
        this.publishers.camera.publishVideo(this.status.video)
      } catch (err) {
        console.info('toggleVideo Errror', err)
      }
    },

    async toggleAudio() {
      try {
        this.status.audio = !this.status.audio
        this.publishers.camera.publishAudio(this.status.audio)
      } catch (err) {
        console.info('toggleVideo Errror', err)
      }
    },

    async switchCamera() {
      try {
        const camera = await this.publishers.camera.cycleVideo()
        console.info('switchCamera', camera)
      } catch (err) {
        console.info('switchCamera error', err)
      }
    },

    checkScreenSharing() {
      return new Promise((resolve) => {
        OT.checkScreenSharingCapability((response) =>
          resolve(
            response.supported &&
              !isMobile &&
              (isChrome ||
                isChromium ||
                isEdgeChromium ||
                isFirefox ||
                isOpera ||
                isSafari)
          )
        )
      })
    },

    async shareScreen() {
      this.publishers.screen = await this.createPublisher('screen')
      // If the publisher could not be created return
      if (!this.publishers.screen) {
        this.$dialog.notify.error(
          'Errore durante la condivisione schermo',
          this.notificationSettings
        )
        return
      }
      this.publishers.screen.on('streamCreated', this.published('screen'))
      this.publishers.screen.on('streamDestroyed', this.unpublished('screen'))

      try {
        await this.publish('screen')
        this.status.screen = true
      } catch (error) {
        this.$dialog.notify.error(
          'Errore durante la condivisione schermo',
          this.notificationSettings
        )
        console.warn('Error with screen share stream', error)
        this.$emit('error', error)
      }
    },
  },
}
</script>

<style scoped></style>
