<template>
  <span>{{ this.session.duration | duration }}</span>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'SessionTimer',
  props: {
    count: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('sessions', {
      session: 'current',
    }),
  },
  data: () => ({
    interval: null,
  }),
  mounted() {
    this.interval = setInterval(() => this.incrementTimer(), 1000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    ...mapMutations('sessions', {
      updateSession: 'SET_CURRENT',
    }),
    incrementTimer() {
      if (this.count)
        this.updateSession({ duration: this.session.duration + 1 })
    },
  },
}
</script>

<style scoped></style>
