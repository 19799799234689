<template>
  <div v-if="session">
    <v-row no-gutters class="v-bar--underline">
      <v-col>
        <h2>
          <small class="font-weight-light">Sessione</small>
          <br />
          {{ getSessionId }}
        </h2>
      </v-col>
      <v-col>
        <h2>
          <small class="font-weight-light">Cliente</small>
          <br />
          {{ current.customer_address | placeholder('-') }}
        </h2>
      </v-col>
      <v-col>
        <h2>
          <small class="font-weight-light">Creazione</small>
          <br />
          {{ current.created_at | momentOr('DD/MM  HH:mm', '-') }}
        </h2>
      </v-col>
      <v-col>
        <h2>
          <small class="font-weight-light">Durata</small>
          <br />
          <SessionTimer :count="!closingDialog" />
        </h2>
      </v-col>
    </v-row>

    <v-row id="session" @error="errorHandler">
      <v-col :cols="12" :sm="12" :md="9">
        <AgentSubscriber
          v-if="session"
          :session="session"
          :streams="subscriberStreams"
          :overlay="overlay"
        />

        <v-row v-if="userDevice" class="caption">
          <v-col cols="3">
            <span class="text--secondary">Browser:</span>
            {{ userDevice.browser }}
            {{ userDevice.browser_ver }}
          </v-col>
          <v-col cols="2">
            <span class="text--secondary">OS:</span>
            {{ userDevice.os }} {{ userDevice.os_ver }}
          </v-col>
          <v-col v-if="userDevice.mobile" cols="3">
            <span class="text--secondary">Dispositivo:</span>
            {{ userDevice.vendor }} {{ userDevice.model }}
          </v-col>
        </v-row>

        <v-alert v-if="!userStatus.permissions" type="warning">
          Attenzione, l'utente ha negato l'accesso a camera e microfono.
        </v-alert>
      </v-col>

      <v-col :cols="12" :sm="5" :md="3">
        <AgentPublisher
          v-if="session"
          :session="session"
          :settings="settings"
          show-controls
          @error="errorHandler"
          @published="addStream"
          @unpublished="removeStream"
        />

        <v-btn
          v-if="!fastAuth"
          :disabled="closing || suspending"
          block
          depressed
          class="mt-2"
          color="warning"
          @click="suspend"
        >
          <v-icon left> mdi-pause </v-icon>
          Sospendi Sessione
        </v-btn>
        <v-btn
          :disabled="closing || suspending"
          block
          depressed
          class="mt-2"
          color="success"
          @click="terminate"
        >
          <v-icon left> mdi-stop </v-icon>
          Termina Sessione
        </v-btn>
        <v-btn
          v-if="!fastAuth"
          block
          depressed
          color="btn_default"
          class="mt-2"
          @click="notifyVideo"
        >
          <v-icon left> mdi-email-send </v-icon>
          Rinvia Mail/SMS
        </v-btn>
        <v-btn
          v-clipboard="() => session_link"
          depressed
          color="btn_default"
          block
          class="mt-2"
        >
          <v-icon left> mdi-link-variant </v-icon>
          Copia link cliente
        </v-btn>
        <v-btn
          v-if="settings.enable_live_editor"
          block
          depressed
          color="btn_default"
          class="mt-2"
          :disabled="!isVideoStreamAvailable"
          @click="acquireImageToEdit"
        >
          <v-icon left color="primary"> mdi-draw </v-icon>
          CATTURA IMMAGINE
        </v-btn>

        <v-btn
          block
          depressed
          color="btn_default"
          class="mt-2"
          :disabled="!isVideoStreamAvailable"
          @click="overlay = !overlay"
        >
          <v-icon left color="primary"> mdi-transition-masked </v-icon>
          {{ overlay ? 'Disattiva' : 'Attiva ' }}
          MASCHERA VIDEO
        </v-btn>

        <v-btn
          v-if="settings.enable_recording && !sessionInfo.recording"
          block
          depressed
          color="info"
          class="mt-2"
          @click="setRecording('start')"
        >
          <v-icon left> mdi-record </v-icon>
          Avvia Registrazione
        </v-btn>
        <v-btn
          v-else-if="settings.enable_recording && sessionInfo.recording"
          block
          depressed
          class="mt-2"
          color="error"
          @click="setRecording('stop')"
        >
          <v-icon left> mdi-record-rec </v-icon>
          Ferma Registrazione
        </v-btn>

        <v-spacer class="my-5" />

        <v-btn
          block
          depressed
          small
          color="btn_default"
          class="mt-2"
          :disabled="!streams.length"
          @click="setCustomerVideo"
        >
          {{ userStatus.video ? 'Disattiva' : 'Attiva ' }}
          Video Cliente
        </v-btn>
        <v-btn
          block
          depressed
          color="btn_default"
          small
          class="mt-2"
          :disabled="!streams.length || !userStatus.video"
          @click="cycleCustomerCamera"
        >
          Inverti Camera Cliente
        </v-btn>

        <v-btn
          block
          depressed
          color="btn_default"
          small
          class="mt-2"
          :disabled="!streams.length"
          @click="setCustomerAudio"
        >
          {{ userStatus.audio ? 'Disattiva' : 'Attiva' }}
          Audio Cliente
        </v-btn>

        <slot name="right" />

        <SessionChat
          v-if="settings.enable_chat"
          :session="session"
          :enable-attachments="settings.enable_attachments"
          sender="Tecnico"
        />
      </v-col>
    </v-row>

    <v-dialog v-model="closingDialog" max-width="75%" :retain-focus="false">
      <v-card>
        <v-card-title>Chiusura Sessione</v-card-title>
        <v-card-text>
          <ClosingForm
            @saved="
              () => {
                closingDialog = false
                canTerminate = true
              }
            "
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editor" :width="640" persistent :retain-focus="false">
      <ImageEditor
        v-if="settings.enable_live_editor"
        ref="editor"
        :height="editorHeight"
        :width="editorWidth"
        @cancel="editor = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import OT from '@opentok/client'
import NetworkTest from 'opentok-network-test-js'

import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import SessionChat from '@components/sessions/SessionChat.vue'
import AgentPublisher from '@components/sessions/inc/AgentPublisher.vue'
import AgentSubscriber from '@components/sessions/inc/AgentSubscriber.vue'
import SessionTimer from '@components/sessions/inc/SessionTimer.vue'
import ClosingForm from '@components/sessions/ClosingForm.vue'
import ImageEditor from '@components/editor/ImageEditor.vue'
import { EventBus, EventsType } from '@services/eventbus.js'
import { flow, property, identity } from 'lodash'
import {
  connectSession,
  createSession,
  signal,
  isPublisherStream,
  isSubscriberStream,
  isVisible,
  initialConfig,
  leaveSession,
} from '@utils/session'

export default {
  name: 'SessionVideo',
  components: {
    ImageEditor,
    ClosingForm,
    SessionTimer,
    AgentSubscriber,
    AgentPublisher,
    SessionChat,
  },
  props: {
    fastAuth: {
      type: Boolean,
      defaultValue: false,
    },
  },
  data: () => ({
    canTerminate: false,
    closing: false,
    closingDialog: false,
    editor: false,
    editorHeight: 480,
    editorWidth: 640,
    messages: [],
    overlay: false,
    session: null,
    stream: null,
    streams: [],
    streamVideo: null,
    suspending: false,
    userDevice: null,
    userStatus: initialConfig.customer.getDefaultStatus(),

    notificationSettings: {
      position: 'top-right',
      timeout: 5000,
    },
  }),
  computed: {
    ...mapState('sessions', ['current']),
    ...mapGetters('sessions', {
      sessionInfo: 'getOTSessionInfo',
      session_closed: 'getSessionClosed',
      getSessionId: 'getSessionId',
    }),
    ...mapGetters('sessions', {
      settings: 'settings',
    }),

    isVideoStreamAvailable() {
      return (
        this.streams.filter(
          (stream) => stream.videoType === 'camera' && stream.hasVideo
        ).length > 0
      )
    },

    session_link() {
      if (this.settings.fe_public_url)
        return `https://${this.settings.fe_public_url}`
      return `${window.location.origin}/v/${this.current.project_key}/${this.current.session_code}-${this.current.client_code}`
    },
    // Your stream has a publisher prop to it
    publisherStreams() {
      return this.streams.filter(isPublisherStream)
    },
    // Other people's stream don't have a publisher prop
    subscriberStreams() {
      return this.streams.filter(isSubscriberStream)
    },
    visibleStreams() {
      return this.subscriberStreams.filter(isVisible)
    },
  },

  async mounted() {
    await this.getSessionToken()
    this.setPending(true)
    try {
      const initialized = await this.initializeSession()
      this.session = await this.connectSession(initialized)
      // Get the sterams that were already present, if any
      this.streams = this.session.streams.where(identity)
    } catch (error) {
      this.errorHandler(error)
      /**
       * The test only checks the user can connect to OT
       * so it only makes sense if something is wrong
       */
      await this.networkTest()
      console.error(error)
      return
    }

    /**
     * This runs every time SOMEBODY ELSE connects,
     * your events are dispatched by the publisher
     */
    this.session.on('streamCreated', ({ stream }) => {
      this.addStream(stream)
      this.signal('cmd', { type: 'get_status' })
    })

    /**
     * This runs every time SOMEBODY ELSE disconnects.
     */
    this.session.on(
      'streamDestroyed',
      flow([property('stream'), this.removeStream])
    )

    this.attachSignalListeners(this.session)

    EventBus.$on(EventsType.IMG_DATA, (data) => {
      this.onImageData(data)
    })
  },

  async beforeDestroy() {
    EventBus.$off(EventsType.IMG_DATA)
    this.resetCurrentSession()
    // This only triggers if you elave the session without terminating

    if (this.session.isConnected()) {
      this.session.disconnect()
    }
  },

  methods: {
    ...mapActions('sessions', [
      'getSessionToken',
      'setRecording',
      'notifyVideo',
      // Set the state and resolution as suspended
      'suspendSession',
      // Set the state and resolution as closed
      'terminateSession',
      'update',
    ]),

    ...mapMutations('sessions', {
      setPending: 'SET_PENDING',
      resetToken: 'RESET_OT_SESSION',
      resetCurrentSession: 'RESET_CURRENT',
    }),

    /**
     * Tests if the user can connect to OT servers
     */
    async networkTest() {
      const { api_key: apiKey, session_id: sessionId, token } = this.sessionInfo
      try {
        const otNetworkTest = new NetworkTest(OT, {
          apiKey,
          sessionId,
          token,
        })

        const result = await otNetworkTest.testConnectivity()
        if (!result.success) throw new Error('connectivity error')
        return true
      } catch (err) {
        console.warn('networkTest error', err)
        /* this.$sentry.captureException(err) */

        this.$dialog.notify.warning(
          'Attenzione, rilevati problemi di connessione. Verificare connettività o la presenza di firewall.',
          this.notificationSettings
        )
      }
    },

    /**
     * This creates a session which is NOT connected
     */
    initializeSession() {
      /* eslint-disable camelcase */
      const { api_key, session_id } = this.sessionInfo
      return createSession(api_key, session_id)
    },
    /**
     * This tells the session to connect to OT
     */
    connectSession(session) {
      const { token } = this.sessionInfo
      return connectSession(token, session)
    },

    addStream(stream) {
      this.streams = [...this.streams, stream]
    },
    removeStream(stream) {
      this.streams = this.streams.filter((s) => s.id !== stream.id)
    },

    setCustomerVideo() {
      this.signal('cmd', { type: 'customer_video' })
    },

    setCustomerAudio() {
      this.signal('cmd', { type: 'customer_audio' })
    },

    cycleCustomerCamera() {
      this.signal('cmd', { type: 'cycle_camera' })
    },

    async suspend() {
      this.suspending = true
      const res = await this.$dialog.confirm({
        text: `Vuoi sospendere la sessione?  Anche il cliente verrà disconnesso.`,
        title: `Sospensione`,
      })

      if (!res) {
        this.suspending = false
        return
      }

      this.setPending(false)
      await this.suspendSession()
      this.resetToken()
      await this.signal('cmd', { type: 'session_suspend' })
      this.streams.filter(isPublisherStream).forEach((stream) => {
        leaveSession(this.session, stream.publisher)
      })
      await this.waitForSessionToBeClear()
      this.session.disconnect()
      this.suspending = false
      return this.$router.push('/manage/sessions')
    },

    async terminate() {
      this.closing = true
      const res = await this.$dialog.confirm({
        text: `Vuoi terminare la sessione?  Anche il cliente verrà disconnesso.`,
        title: `Termina`,
      })

      if (!res) {
        this.closing = false
        return
      }

      this.setPending(false)
      /**
       * If the session should terminate automatically
       * will do so and the next promise will
       * resolve straight away
       * Otherwise waits for the operator to submit the
       * form before resolving the promise
       */
      if (!this.settings.enable_closing_modal) {
        await this.terminateSession()
        this.canTerminate = true
      } else {
        this.closingDialog = true
      }
      this.resetToken()
      this.signal('cmd', { type: 'session_end' })
      this.streams.filter(isPublisherStream).forEach((stream) => {
        leaveSession(this.session, stream.publisher)
      })
      await this.waitForResolution()
      await this.waitForSessionToBeClear()
      this.session.disconnect()
      this.closing = false

      if (this.fastAuth) {
        return this.$router.push('/v/done')
      }
      return this.$router.push('/manage/sessions')
    },

    /**
     * Waits until there are no more streams.
     * Meaning that customer has unpublished his streams.
     */
    waitForSessionToBeClear() {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          this.$nextTick(() => {
            if (!this.streams.length) {
              clearInterval(interval)
              resolve(true)
            }
          })
        }, 500)
      })
    },

    /**
     * Resolution can either be set manually in the closing form
     * or automatically by terminateSession, this promise conflate the two flows
     */
    async waitForResolution() {
      return new Promise((resolve) => {
        this.interval = setInterval(() => {
          if (this.canTerminate) {
            clearInterval(this.interval)
            resolve(true)
          }
        }, 200)
      })
    },

    async signal(type, data) {
      try {
        return signal(this.session, type, data, {
          beforeSend: this.addMetadata,
        })
      } catch (error) {
        this.$dialog.notify.warning(
          'Impossibile inviare i dati: disconnesso dal server.',
          this.notificationSettings
        )
      }
    },
    addMetadata(data) {
      return { ...data, ts: new Date().getTime(), sender: 'Operatore' }
    },

    attachSignalListeners(session) {
      const pairs = [
        [
          'user_current_status',
          (event) => {
            const { status, device } = this.getEventData(event)
            this.userStatus = status
            this.userDevice = device
          },
        ],
      ]
      for (const [event, listener] of pairs) {
        session.on(`signal:${event}`, listener)
      }
    },
    /** Extracts the relevant parts of an event */
    getEventData: flow([property('data'), JSON.parse]),

    acquireImageToEdit() {
      EventBus.$emit(EventsType.REQUEST_FRAME)
    },

    onImageData(image) {
      console.info(`New Image to Edit ${image.width}x${image.height}`)
      this.editorWidth = image.width
      this.editorHeight = image.height
      this.editor = true

      this.$nextTick(() => {
        this.$refs.editor.setImage(`data:image/png;base64,${image.data}`)
        this.$refs.editor.clear()
      })
    },

    async errorHandler(err) {
      console.warn('OT Error: ', err)

      switch (err.name) {
        case 'NO_AUDIO_CAPTURE_DEVICES':
        case 'NO_VIDEO_CAPTURE_DEVICES':
        case 'FAILED_TO_OBTAIN_MEDIA_DEVICES':
        case 'OT_USER_MEDIA_ACCESS_DENIED':
          // workaround to avoid error when stopping share screen
          if (err.message.indexOf('screen sharing') > 0) return

          // utente nega i permessi
          await this.$dialog.error({
            text:
              "Non è possibile procedere senza i permessi necessari per l'accesso al microfono e/o fotocamera",
            title: 'Attenzione',
            actions: ['Chiudi'],
          })

          // torno all'elenco
          await this.setPending(false)
          // await this.$router.push({ name: 'sessions_pending' })
          break

        case 'API_CONNECTIVITY_ERROR':
        case 'CONNECT_TO_SESSION_ERROR':
        case 'PUBLISH_TO_SESSION_ERROR':
        case 'FAILED_MESSAGING_SERVER_TEST':
          // errore di connessione
          await this.$dialog.error({
            text:
              'Non è stato possibile raggiungere correttamente il server. Assicurarsi di essere connessi correttamente ad internet e che non vi siano firewall che bloccano la navigazione.',
            title: 'Attenzione',
            actions: ['Chiudi'],
          })

          // torno all'elenco
          await this.setPending(false)
          // await this.$router.push({ name: 'sessions_pending' })
          break

        default:
        /* this.$sentry.captureException(err) */
      }
    },
  },
}
</script>

<style>
.OT_subscriber {
  float: left;
}
.OT_publisher {
  float: left;
}
</style>
