<template>
  <v-card outlined>
    <v-responsive :aspect-ratio="16 / 9">
      <v-overlay
        absolute
        :opacity="0.99"
        :value="overlay"
        style="z-index: 100"
      />
      <v-card-text v-if="!streams.length" class="text-center position-relative">
        In attesa del video interlocutore.
      </v-card-text>

      <SubscriberVideo
        v-for="stream in streams"
        :key="stream.streamId"
        :ref="stream.streamId"
        :session="session"
        :stream="stream"
      />
    </v-responsive>
  </v-card>
</template>

<script>
import OT from '@opentok/client'
import { mapGetters } from 'vuex'
import SubscriberVideo from './SubscriberVideo.vue'

export default {
  name: 'AgentSubscriber',
  components: { SubscriberVideo },
  props: {
    session: {
      type: OT.Session,
      required: true,
      default() {
        return {}
      },
    },
    streams: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
    overlay: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    subscriberOptions: {
      showControls: false,
      width: '100%',
      height: '100%',
      insertMode: 'append',
      fitMode: 'contain',
    },
  }),
  computed: {
    ...mapGetters('adminProjects', {
      settings: 'getSettings',
    }),
  },
  methods: {},
}
</script>

<style lang="scss">
.subscriber-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
</style>
