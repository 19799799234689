<template>
  <div>
    <v-card>
      <v-toolbar flat color="bg_section">
        <v-toolbar-items>
          <v-btn icon :ripple="false" @click="drag">
            <v-icon>mdi-cursor-pointer</v-icon>
          </v-btn>

          <v-btn icon :ripple="false" @click="setTool('freeDrawing')">
            <v-icon>mdi-lead-pencil</v-icon>
          </v-btn>

          <v-btn icon :ripple="false" @click="setTool('circle')">
            <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
          </v-btn>

          <v-btn icon :ripple="false" @click="setTool('rect')">
            <v-icon>mdi-shape-square-plus</v-icon>
          </v-btn>

          <v-btn icon :ripple="false" @click="setTool('arrow')">
            <v-icon>mdi-arrow-top-right</v-icon>
          </v-btn>

          <v-btn icon :ripple="false" @click="setTool('text')">
            <v-icon>mdi-format-text</v-icon>
          </v-btn>

          <!-- <v-btn
            v-if="currentActiveMethod === 'crop'"
            icon
            :ripple="false"
            @click="applyCropping"
          >
            <v-icon>mdi-target-variant</v-icon>
          </v-btn>
          <v-btn v-else icon :ripple="false" @click="cropImage">
            <v-icon>mdi-crop</v-icon>
          </v-btn>-->

          <v-divider vertical inset class="mx-3"></v-divider>
          <v-btn icon :ripple="false" @click="undo">
            <v-icon>mdi-undo-variant</v-icon>
          </v-btn>

          <v-btn icon :ripple="false" @click="redo">
            <v-icon>mdi-redo-variant</v-icon>
          </v-btn>

          <v-btn icon :ripple="false" @click="clear">
            <v-icon>mdi-layers-off</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
          <v-divider vertical inset class="mx-3"></v-divider>
          <!--          <v-btn icon :ripple="false" @click="setImage">-->
          <!--            <v-icon>mdi-file-send</v-icon>-->
          <!--          </v-btn>-->
          <v-btn icon :ripple="false" @click="saveImage">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <BaseEditor
        v-if="data"
        ref="editor"
        :canvas-width="width"
        :canvas-height="height"
      />

      <v-card-actions>
        <v-avatar :color="color" size="36">
          <span class="white--text headline"></span>
        </v-avatar>

        <v-divider vertical class="mx-3"></v-divider>

        <v-avatar
          v-for="c in colors"
          :key="c"
          v-ripple
          :color="c"
          size="24"
          class="ml-2"
          @click="color = c"
        >
          <span class="white--text headline"></span>
        </v-avatar>

        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="$emit('cancel')">
          Annulla
        </v-btn>
        <v-btn color="primary" depressed @click="sendImage">INVIA</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import BaseEditor from '@components/editor/BaseEditor.vue'
import { EventBus, EventsType } from '@services/eventbus.js'
export default {
  name: 'ImageEditor',
  components: { BaseEditor },
  data: () => ({
    currentActiveMethod: null,
    params: {},
    data: null,
    width: 640,
    height: 480,
    color: '#e40000',
    imageUrl: null,
    croppedImage: false,
    fontSettings: {
      fontFamily: 'Arial',
      fontSize: '22',
      fontWeight: '12',
    },
    colors: [
      '#e40000',
      '#e8eb34',
      '#a834eb',
      '#65c31a',
      '#34b7eb',
      '#eb34df',
      '#1a10ad',
      '#000000',
    ],
  }),
  watch: {
    color: {
      immediate: false,
      handler(value) {
        this.changeColor(value)
        const prevMode = this.currentActiveMethod
        this.setTool('selectMode')
        this.setTool(prevMode)
      },
    },
  },
  methods: {
    getImageDimensions(file) {
      return new Promise((resolve) => {
        const i = new Image()
        i.onload = () => {
          resolve({ width: i.width, height: i.height })
        }

        i.src = file
      })
    },

    setImage(data) {
      this.getImageDimensions(data).then((sizes) => {
        console.info('detected size', sizes)
        this.width = sizes.width
        this.height = sizes.height
        this.data = data

        this.$nextTick(() => {
          this.$refs.editor.setBackgroundImage(data)
        })
      })
    },
    cropImage() {
      this.currentActiveMethod = 'crop'
      this.setTool('crop')
    },
    applyCropping() {
      this.currentActiveMethod = ''
      this.$refs.editor.applyCropping()
    },
    changeColor(colorHex) {
      this.$refs.editor.changeColor(colorHex)
    },

    saveImage() {
      const image = this.$refs.editor.saveImage()
      this.saveImageAsFile(image)
    },

    saveImageAsFile(base64) {
      const link = document.createElement('a')
      link.setAttribute('href', base64)
      link.setAttribute('download', 'image-markup')
      link.click()
    },

    setTool(type, params) {
      this.currentActiveMethod = type

      if (type === 'text')
        this.$refs.editor.set(type, {
          fontStyle: 'italic',
          fontFamily: 'Arial',
          fontSize: '24',
          fontWeight: '15',
          placeholder: 'Digita testo...',
        })
      else this.$refs.editor.set(type, params)
    },

    uploadImage(e) {
      this.$refs.editor.uploadImage(e)
    },

    clear() {
      this.currentActiveMethod = this.clear
      this.$refs.editor.clear()
      this.setImage(this.data)
    },
    undo() {
      this.currentActiveMethod = this.undo
      this.$refs.editor.undo()
    },
    redo() {
      this.currentActiveMethod = this.redo
      this.$refs.editor.redo()
    },
    drag() {
      this.currentActiveMethod = 'drag'
      this.$refs.editor.drag()
    },

    sendImage() {
      EventBus.$emit(EventsType.SEND_CHAT_IMAGE, this.$refs.editor.saveImage())
      this.clear()
      this.$emit('cancel')
    },
  },
}
</script>

<style scoped></style>
