<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit, invalid }">
      <form
        autocomplete="off"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="reset"
      >
        <v-row dense>
          <FormItem
            v-model="getSessionId"
            type="text"
            readonly
            name="Identificativo Sessione"
            :cols="6"
            :md="4"
            :lg="4"
            :xl="4"
          ></FormItem>
        </v-row>
        <v-row dense>
          <FormItem
            v-model="external_id"
            type="text"
            name="Link Account"
            label="Link Account"
            placeholder="Identificativo esigenza"
            hint="Inserisci un identificativo esigenza di un eventuale sistema esterno"
            :cols="6"
            :md="6"
            :lg="6"
            :xl="6"
          />

          <FormItem
            v-model="durationFormatted"
            type="text"
            disabled
            name="Durata Sessione"
            :cols="3"
            :md="3"
            :lg="2"
            :xl="2"
          ></FormItem>

          <FormItem
            v-if="type === 'remote'"
            v-model="customer_name"
            type="text"
            name="Indirizzo"
            label="Riferimento Cliente"
            placeholder="Inserisci riferimento cliente (opzionale)"
            :cols="6"
            :md="12"
            :lg="12"
            :xl="12"
          ></FormItem>
          <FormItem
            v-model="status"
            :values="closingStatuses"
            type="select"
            name="Stato"
            rules="required"
            :cols="12"
            :md="6"
            :lg="6"
            :xl="6"
          ></FormItem>
          <FormItem
            v-model="resolution"
            :values="resolutionsByStatus(status)"
            type="select"
            name="Esito"
            rules="required"
            :cols="12"
            :md="6"
            :lg="6"
            :xl="6"
          ></FormItem>

          <FormItem
            v-model="notes"
            type="textarea"
            name="Indirizzo"
            label="Note Intervento"
            placeholder="Inserisci note intervento"
            rows="6"
            :cols="12"
            :md="12"
            :lg="12"
            :xl="12"
          ></FormItem>
        </v-row>
        <v-btn
          type="submit"
          :loading="loading"
          :disabled="invalid"
          block
          color="green"
          :dark="!invalid"
        >
          Salva Modifiche
        </v-btn>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import FormItem from '@components/forms/FormItem.vue'

const { mapFields } = createHelpers({
  getterType: 'sessions/getCurrent',
  mutationType: 'sessions/SET_CURRENT_FIELDS',
})
export default {
  name: 'ClosingForm',
  components: { FormItem },
  computed: {
    ...mapFields([
      'customer_address',
      'customer_name',
      'scheduled_at',
      'project_key',
      'status',
      'resolution',
      'notes',
      'type',
      'external_id',
      'duration',
    ]),
    ...mapState('sessions', ['loading']),
    ...mapGetters('sessions', ['getSessionId']),
    ...mapGetters('sessions', ['closingStatuses', 'resolutionsByStatus']),
    durationFormatted() {
      return this.$options.filters.duration(this.duration)
    },
  },
  methods: {
    ...mapMutations('sessions', {
      set: 'SET_CURRENT',
      reset: 'RESET_CURRENT',
    }),
    ...mapActions('sessions', ['update']),

    async onSubmit() {
      await this.update()
      this.$emit('saved')
    },

    onReset() {
      this.reset()
      this.$nextTick(() => {
        this.$refs.observer.reset()
      })
    },
  },
}
</script>

<style scoped></style>
