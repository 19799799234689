/* eslint-disable */
import { initSession, initPublisher } from '@opentok/client'
import { curry, identity } from 'lodash'

const ifElse = curry((predicate, whenTrue, whenFalse) => (a) =>
  predicate(a) ? whenTrue(a) : whenFalse(a)
)

/**
 * Creates a session and returns it.
 * @returns Promise<Session | Error>
 */
export const createSession = (api_key, session_id) =>
  new Promise((resolve, reject) => {
    try {
      const session = initSession(api_key, session_id)
      resolve(session)
    } catch (error) {
      reject(error)
    }
  })

/**
 * Creates a publisher that points to a DOM element ID and returns it.
 * @returns Promise<Publisher | Error>
 */
export const createPublisher = curry((
  elementId,
  options = {
    insertMode: 'append',
    width: '300px',
    height: '300px',
  }) =>
  new Promise((resolve, reject) => {
    const publisher = initPublisher(
      elementId,
      options,
      ifElse(identity, reject, () => resolve(publisher))
    )
  })
)
/**
 * Connects a session using a token and returns the session
 * @returns Promise<Session | Error>
 */
export const connectSession = curry(
  (token, session) =>
    new Promise((resolve, reject) =>
      session.connect(
        token,
        ifElse(identity, reject, () => resolve(session))
      )
    )
)

/**
 * Given a session exists, sends your stream to a publisher element.
 * @returns Promise<Session | Error>
 */
export const broadcastToPublisher = curry(
  (session, publisher) =>
    new Promise((resolve, reject) =>
      session.publish(
        publisher,
        ifElse(identity, reject, () => resolve(session))
      )
    )
)
