<template>
  <div>
    <video
      v-show="false"
      id="video"
      ref="video"
      :width="width"
      :height="height"
      class="fill-height"
      autoplay
      playsinline
      @loadedmetadata="resizeCanvas"
    ></video>

    <img v-show="false" ref="backgroundImage" :src="background" />

    <canvas
      v-show="false"
      ref="backgroundCanvas"
      :width="width"
      :height="height"
    />

    <canvas id="canvas" ref="canvas" />
  </div>
</template>

<script>
import '@tensorflow/tfjs'
import * as bodyPix from '@tensorflow-models/body-pix'

export default {
  name: 'CameraEffectSource',
  props: {
    width: {
      type: Number,
      default: 640,
    },
    height: {
      type: Number,
      default: 480,
    },
    background: {
      type: String,
      default: '@assets/bg_placeholder.jpg',
    },
    enableVideo: {
      type: Boolean,
      default: false,
    },
    blur: {
      type: Boolean,
      default: false,
    },
    fakeBackground: {
      type: Boolean,
      default: false,
    },
    frontCamera: {
      type: Boolean,
      default: false,
    },
    loadTensorFlow: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    ready: false,
    net: null,
    stream: null,
    interval: null,
    bodyPixSettings: {
      // architecture: "MobileNetV1",
      stride: 16,
      outputStride: 16,
      quantBytes: 2,
      multiplier: 0.75,
    },
    segmentationSettings: {
      maxDetections: 2,
      internalResolution: 'high',
      segmentationThreshold: 0.75,
    },
    blurSettings: {
      backgroundBlurAmount: 7,
      edgeBlurAmount: 10,
      flipHorizontal: false,
    },
  }),
  watch: {
    frontCamera() {
      this.startVideo()
    },
    enableVideo: {
      immediate: true,
      handler(enable) {
        console.log('enable video is..', enable)
        if (enable) return this.startVideo()
        return this.stopVideo()
      },
    },
  },
  mounted() {
    this.init()
    this.initBackgroundCanvas()
  },
  beforeDestroy() {
    this.stopVideo()
  },
  methods: {
    async init() {
      if (this.loadTensorFlow) await this.loadBodyPix()

      // {width: 768, height:576},
      // {width: 1024, height:576},
      // {width: 1280, height:720},
      // requestAnimationFrame(this.renderFrame)
    },

    async startVideo() {
      console.info('start video...')
      this.stream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: { ideal: this.width },
          height: { ideal: this.height },
          frameRate: 15,
          facingMode: this.frontCamera ? 'user' : 'environment',
        },
        audio: false,
      })

      this.$refs.video.srcObject = this.stream
      this.$refs.video.play()

      this.interval = setInterval(this.renderFrame, 1000 / 15)
    },

    stopVideo() {
      if (!this.stream) return

      clearInterval(this.interval)
      return this.stream.getTracks().forEach((track) => track.stop())
    },

    initBackgroundCanvas() {
      const backgroundImage = this.$refs.backgroundImage
      const backgroundCanvasCtx = this.$refs.backgroundCanvas.getContext('2d')

      backgroundCanvasCtx.drawImage(backgroundImage, 0, 0)
    },

    renderFrame() {
      if (this.$refs.video?.readyState === this.$refs.video?.HAVE_ENOUGH_DATA) {
        if (this.blur) {
          this.blurBackground()
        } else if (this.fakeBackground) {
          this.replaceBackground()
        } else {
          this.drawFrame()
        }
      }

      // requestAnimationFrame(this.renderFrame)
    },

    resizeCanvas() {
      console.info(
        'Getting Canvas Real Size',
        this.$refs.video.videoWidth,
        this.$refs.video.videoHeight
      )

      this.$refs.canvas.height = this.$refs.video.videoHeight
      this.$refs.canvas.width = this.$refs.video.videoWidth
    },

    async loadBodyPix() {
      try {
        console.info('loading bodyPix')
        this.net = await bodyPix.load(this.bodyPixSettings)
        this.ready = true
        console.info('bodyPix ready')

        this.$emit('bodyPixReady')
      } catch (err) {
        console.warn('error loading bodyPix', err)
      }
    },

    async getBodySegmentation() {
      return this.net.segmentPerson(this.$refs.video, this.segmentationSettings)
    },

    drawFrame() {
      this.$refs.canvas
        .getContext('2d')
        .drawImage(
          this.$refs.video,
          0,
          0,
          this.$refs.canvas.width,
          this.$refs.canvas.height
        )
    },

    async blurBackground() {
      const segmentation = await this.getBodySegmentation()

      bodyPix.drawBokehEffect(
        this.$refs.canvas,
        this.$refs.video,
        segmentation,
        this.blurSettings.backgroundBlurAmount,
        this.blurSettings.edgeBlurAmount,
        this.blurSettings.flipHorizontal
      )
    },

    async replaceBackground() {
      const personSegmentation = await this.getBodySegmentation()

      const canvasPerson = this.$refs.canvas
      const canvasBg = this.$refs.backgroundCanvas
      const camera = this.$refs.video
      const contextPerson = canvasPerson.getContext('2d')

      contextPerson.drawImage(camera, 0, 0, camera.width, camera.height)
      const imageData = contextPerson.getImageData(
        0,
        0,
        camera.width,
        camera.height
      )
      const pixel = imageData.data

      for (let p = 0; p < pixel.length; p += 4) {
        if (personSegmentation.data[p / 4] === 0) {
          pixel[p + 3] = 0
        }
      }

      const backgroundImage = this.$refs.backgroundImage
      const backgroundCanvasCtx = this.$refs.backgroundCanvas.getContext('2d')

      contextPerson.drawImage(backgroundImage, 0, 0)

      backgroundCanvasCtx.imageSmoothingEnabled = true
      backgroundCanvasCtx.putImageData(imageData, 0, 0)

      contextPerson.drawImage(canvasBg, 0, 0, camera.width, camera.height)
    },

    getVideoTrack(fps) {
      return this.$refs.canvas.captureStream(fps).getVideoTracks()[0]
    },
  },
}
</script>

<style scoped></style>
